<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
import resize from '../mixins/resize'

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    name: {
      type: String,
      default: ''
    },
    chartTitle: {
      type: String,
      default: ''
    },
    legend: {
      type: Array,
      default() {
        return []
      }
    },
    xAxisData: {
      type: Array,
      default() {
        return []
      }
    },
    yAxisData: {
      type: Array,
      default() {
        return []
      }
    },
    randomCode: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      chart: null
    }
  },
  watch: {
    randomCode: {
      deep: true,
      handler() {
          this.$nextTick(() => {
            this.initChart()
          })
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons')
      this.chart.setOption({
        backgroundColor: '',
        title: {
          text: this.chartTitle,
          textStyle: {
            color: '#ffffff',
            fontWeight: 'bold',
            fontSize: 14
          }
        },
        tooltip: {
          trigger: 'axis',
          triggerOn: 'mousemove', // 鼠标移动时触发
          axisPointer: {
            type: 'none'
          },
          backgroundColor: 'rgba(0,0,0,0.8)'
        },
        legend: {
          top: 30,
          textStyle: {
            color: '#fft'
          },
          data: this.legend
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            nameTextStyle: {
              color: '#ffffff',
              fontSize: 16,
              padding: [50, 0, 0, 10]
            },
            type: 'category',
            boundaryGap: true,
            data: this.xAxisData,
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            offset: 10,
            splitLine: {
              show: false,
              lineStyle: {
                type: 'dashed',
                color: ['#ffffff']
              }
            },
            axisLabel: {
              interval: 0, //强制文字产生间隔
              rotate: 45,
              show: true,
              textStyle: {
                color: '#ffffff',
                fontSize: 12
              }
            },
            axisPointer: {
              //基准线
              lineStyle: {
                color: '#fffff'
              }
            }
          }
        ],
        yAxis: [
          {
            axisLabel: {
              show: true,
              textStyle: {
                color: '#ffffff',
                fontSize: 12
              }
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            offset: 1,
            splitLine: {
              show: false,
              lineStyle: {
                type: 'dashed',
                color: ['#ffffff']
              }
            }
          },

          {
            type: "value",
            name: "",
            position: "right",
            axisLabel: {
              formatter: "{value}%", //使图变成百分比形式
              show: true,
              textStyle: {
                color: '#ffffff',
                fontSize: 12
              }
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            offset: 0,
            splitLine: {
              show: false,
              lineStyle: {
                type: 'dashed',
                color: ['#ffffff']
              }
            }
          }
        ],
        series: this.yAxisData
      })
    }
  }
}
</script>
<style lang="scss" scope>
.chart{
  margin-top:30px
}
</style>