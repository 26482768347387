<template>
  <div>
    <v-scale-screen :fullScreen="false" width="1920" height="1080">
      <div class="screenBox">
        <!--背景图-->
        <div class="videobgd">
          <div class="overlay"></div>
          <video ref="videobgd" autoplay muted loop style="width: 100%; height: 100%; object-fit: cover">
            <source src="@/assets/img/bg.mp4" type="video/mp4" />
          </video>
        </div>
        <!-- 顶部标题 -->
        <div class="top">
          <div class="top_title">征和化工数字化看板</div>
        </div>

        <div class="allCont">
          <!-- 左 -->
          <div class="contLeft">
            <div class="nsLeft">
              <div class="left-center">
                <div class="flex_space flex_center_align" style="margin-left: 24px">
                  <div class="lc_title">原料</div>
                </div>
                <div class="line"></div>
                <div class="panel1">
                  <div class="common_panel">
                    <div class="panel_top">
                      <div class="panel_title">数量</div>
                      <div class="p_line"></div>
                    </div>
                    <div class="panel_material">
                      <div>
                        <el-radio-group v-model="materialRadio" size="small" @change="materialRadioChange">
                          <el-radio-button label="0" >今天</el-radio-button>
                          <el-radio-button label="1"  style="margin-left: 90px;">本月</el-radio-button>
                          <el-radio-button label="2" style="margin-left: 90px;">今年</el-radio-button>
                        </el-radio-group>
                      </div>
                      <!--                      <div class="panel_material_box">-->
                      <!--                        <div class="material_btn_box flex_center flex_center_align">-->
                      <!--                          <div class="item_btn">今天</div>-->
                      <!--                        </div>-->
                      <!--                        <div class="material_btn_box flex_center flex_center_align">-->
                      <!--                          <div class="item_btn">本月</div>-->
                      <!--                        </div>-->
                      <!--                        <div class="material_btn_box flex_center flex_center_align">-->
                      <!--                          <div class="item_btn">今年</div>-->
                      <!--                        </div>-->

                      <!--                      </div>-->
                      <div class="materialVue">
                        <div class="echartsBg">
                          <div class="age_num">{{ materialSum }}</div>
                        </div>
                        <div class="circle" ref="materialCircle"
                             style="width: 100%;height: 100%;border: 1px solid transparent;
                        -webkit-tap-highlight-color: transparent;user-select: none;"/>
                        <!--说明-->
                        <div class="echartsLeg">
                          <div class="flex_start">
                            <div class="circle" style="border-color: #4db9a5"></div>
                            <div>
                              <div class="panel_cun_name" style="cursor: pointer">消耗量</div>
                              <div class="panel_cun_num">{{ materialUseCount }}</div>
                            </div>
                          </div>
                          <div class="flex_start">
                            <div class="circle" style="border-color: #9523cb"></div>
                            <div>
                              <div class="panel_cun_name" style="cursor: pointer">剩余量</div>
                              <div class="panel_cun_num">{{ materialResidueCount }}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="common_panel">
                    <div class="panel_top">
                      <div class="panel_title">入库数量走势</div>
                      <div class="p_line"></div>
                    </div>
                    <div style="width: 100%;padding: 15px 0 10px 34px;">
                      <div class="flex_center_align">
                        <div>
                          <span>原料：</span>
                          <el-select v-model="materialValue" @change="materialChange" placeholder="请选择" style="width:150px">
                            <el-option v-for="item in materialOptions" :key="item.value" :label="item.label"
                                       :value="item.value">
                            </el-option>
                          </el-select>
                        </div>
                        <div style="margin-left: 20px;">
                          <span>时间：</span>
                          <el-date-picker
                              v-model="materialDate"
                              type="date"
                              valueFormat="yyyy-MM-dd"
                              @change="materialChange"
                              style="width:195px"
                              placeholder="请选择">
                          </el-date-picker>
                        </div>
                      </div>
                      <div style="margin-right: 20px">
                        <MoreLineChart v-bind="materialObj"></MoreLineChart>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div class="left-center" style="margin-top: 25px">
                <div class="flex_space flex_center_align" style="margin-left: 24px">
                  <div class="lc_title">原料</div>
                </div>
                <div class="line"></div>
                <div class="common_panel">
                  <div class="panel_top">
                    <div class="panel_title">库存数量</div>
                    <div class="p_line"></div>
                  </div>
                  <div class="line_box">
                    <div class="flex_center_align">
                      <div>
                        <span>原料：</span>
                        <el-select v-model="supMaterValue" @change="supMaterChange" placeholder="请选择" style="width:150px">
                          <el-option v-for="item in materialOptions" :key="item.value" :label="item.label"
                                     :value="item.value">
                          </el-option>
                        </el-select>
                      </div>
                      <div style="margin-left: 20px;">
                        <span>时间：</span>
                        <el-date-picker
                            v-model="supMaterDate"
                            type="date"
                            valueFormat="yyyy-MM-dd"
                            @change="supMaterDateChange"
                            style="width:195px"
                            placeholder="请选择">
                        </el-date-picker>
                      </div>
                    </div>
                    <div ref="industry" style="height: 220px; margin: 0 auto"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="contCenter">
            <div class="nscenter">
              <!-- 图片 -->
              <div class="center_img">
                <el-carousel :interval="3000" height="358px">
                  <el-carousel-item v-for="(item, index) in imgList" :key="index">
                    <img :src="item" style="width: 100%;height: 100%" />
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div class="center_bottom">
                <div class="left-center center_bleft">
                  <div class="flex_space flex_center_align">
                    <div class="lc_title">质检</div>
                  </div>
                  <div class="line"></div>
                  <div class="common_panel">
                    <div style="height: 235px">
                      <div class="party_box">
                        <div class="party_ins">
                          <div class="echartsBg">
                            <div class="age_num">成品</div>
                          </div>
                          <div ref="endProduct" style="width: 178px;height: 178px;border: 1px solid transparent;
                            -webkit-tap-highlight-color: transparent;user-select: none;"></div>
                        </div>
                        <div class="party_ins">
                          <div class="echartsBg">
                            <div class="age_num">半成品</div>
                          </div>
                          <div ref="partyage" style="width: 178px;height: 178px;border: 1px solid transparent;
                            -webkit-tap-highlight-color: transparent;user-select: none;"></div>
                        </div>

                        <div class="party_ins">
                          <div class="echartsBg">
                            <div class="age_num">原料</div>
                          </div>
                          <div ref="materialInspect" style=" width: 178px;height: 178px;border: 1px solid transparent;
                            -webkit-tap-highlight-color: transparent;user-select: none; "
                          ></div>
                        </div>
                      </div>
                      <div style="display: flex">
                        <div class="party_desc">
                          <div class="flex_start" style="margin-left: 37px">
                            <div class="circle" style="border-color: #4db9a5"></div>
                            <div>
                              <div class="panel_cun_name" style="cursor: pointer">合格</div>
                              <div class="panel_cun_num">{{ inspectTotal.endProductOkCount }}</div>
                            </div>
                          </div>
                          <div class="flex_start" style="position: absolute;bottom: 0;right: 18px;">
                            <div class="circle" style="border-color: #F9B814"></div>
                            <div>
                              <div class="panel_cun_name" style="cursor: pointer">不合格</div>
                              <div class="panel_cun_num">{{ inspectTotal.endProductNoCount }}</div>
                            </div>
                          </div>
                        </div>
                        <div class="party_desc">
                          <div class="flex_start" style="margin-left: 37px">
                            <div class="circle" style="border-color: #4db9a5"></div>
                            <div>
                              <div class="panel_cun_name" style="cursor: pointer">合格</div>
                              <div class="panel_cun_num">{{ inspectTotal.halfProductOkCount }}</div>
                            </div>
                          </div>
                          <div class="flex_start" style="position: absolute;bottom: 0;right: 18px;">
                            <div class="circle" style="border-color: #F9B814"></div>
                            <div>
                              <div class="panel_cun_name" style="cursor: pointer">不合格</div>
                              <div class="panel_cun_num">{{ inspectTotal.halfProductNoCount }}</div>
                            </div>
                          </div>
                        </div>
                        <div class="party_desc">
                          <div class="flex_start" style="margin-left: 37px">
                            <div class="circle" style="border-color: #4db9a5"></div>
                            <div>
                              <div class="panel_cun_name" style="cursor: pointer">合格</div>
                              <div class="panel_cun_num">{{ inspectTotal.materialOkCount }}</div>
                            </div>
                          </div>
                          <div class="flex_start" style="position: absolute;bottom: 0;right: 18px;">
                            <div class="circle" style="border-color: #F9B814"></div>
                            <div>
                              <div class="panel_cun_name" style="cursor: pointer">不合格</div>
                              <div class="panel_cun_num">{{ inspectTotal.materialNoCount }}</div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div class="center_bottom" style="margin-top: 20px">
                <div class="left-center center_bleft">
                  <div class="flex_space flex_center_align">
                    <div class="lc_title">采购列表</div>
                  </div>
                  <div class="line"></div>
                  <div class="common_panel">
                    <div class="bottom_table">
                      <el-table :data="procureList" height="216px" ref="tab" id="boxed">
                        <el-table-column prop="number" label="单号" width="160" align="center"> </el-table-column>
                        <el-table-column prop="name" label="名称" width="160" align="center"> </el-table-column>
                        <el-table-column prop="supplierCode" label="供应商" width="160" align="center"> </el-table-column>
                        <el-table-column prop="materialCode" label="材料" width="130" align="center"> </el-table-column>
                        <el-table-column prop="procureUserName" label="采购人" width="100" align="center"> </el-table-column>
                        <el-table-column prop="procureTime" label="采购时间" width="160" align="center"> </el-table-column>
                      </el-table>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>


          <!-- 右 -->
          <div class="contRight">
            <div class="nav_right flex_center">
              <div class="date_box">
                <div>{{ nowDate }}</div>
              </div>
            </div>
            <div class="nsright">
              <div class="left-center">
                <div class="flex_space flex_center_align">
                  <div class="lc_title">产品</div>
                </div>
                <div class="line"></div>
                <div class="panel1">
                  <div class="common_panel">
                    <div class="panel_top">
                      <div class="panel_title">数量</div>
                      <div class="p_line"></div>
                    </div>
                    <div class="panel_material">
                      <div>
                        <el-radio-group v-model="productRadio" size="small" @change="productRadioChange">
                          <el-radio-button label="0">今天</el-radio-button>
                          <el-radio-button label="1"  style="margin-left: 90px;">本月</el-radio-button>
                          <el-radio-button label="2" style="margin-left: 90px;">今年</el-radio-button>
                        </el-radio-group>
                      </div>
                      <!--                      <div class="panel_material_box">-->
                      <!--                        <div class="material_btn_box flex_center flex_center_align">-->
                      <!--                          <div class="item_btn">今天</div>-->
                      <!--                        </div>-->
                      <!--                        <div class="material_btn_box flex_center flex_center_align">-->
                      <!--                          <div class="item_btn">本月</div>-->
                      <!--                        </div>-->
                      <!--                        <div class="material_btn_box flex_center flex_center_align">-->
                      <!--                          <div class="item_btn">今年</div>-->
                      <!--                        </div>-->

                      <!--                      </div>-->
                      <div class="materialVue">
                        <div class="echartsBg">
                          <div class="age_num">{{ productSum }}</div>
                        </div>
                        <div class="circle" ref="productCircle"
                             style="width: 100%;height: 100%;border: 1px solid transparent;
                        -webkit-tap-highlight-color: transparent;user-select: none;"/>
                        <!--说明-->
                        <div class="echartsLeg">
                          <div class="flex_start">
                            <div class="circle" style="border-color: #4db9a5"></div>
                            <div>
                              <div class="panel_cun_name" style="cursor: pointer">消耗量</div>
                              <div class="panel_cun_num">{{ productUseCount }}</div>
                            </div>
                          </div>
                          <div class="flex_start">
                            <div class="circle" style="border-color: #9523cb"></div>
                            <div>
                              <div class="panel_cun_name" style="cursor: pointer">剩余量</div>
                              <div class="panel_cun_num">{{ productResidueCount }}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="common_panel">
                    <div class="panel_top">
                      <div class="panel_title">数量统计</div>
                      <div class="p_line"></div>
                    </div>
                    <div style="width: 100%;padding: 15px 0 10px 34px;">
                      <div class="flex_center_align">
                        <div>
                          <span>产品：</span>
                          <el-select v-model="productValue" @change="getProductRecordCount" placeholder="请选择" style="width:220px">
                            <el-option v-for="item in productList" :key="item.value" :label="item.label"
                                       :value="item.value">
                            </el-option>
                          </el-select>
                        </div>
                        <div style="margin-left: 20px;">
                          <span>时间：</span>
                          <el-date-picker
                              v-model="productDate"
                              @change="getProductRecordCount"
                              type="month"
                              valueFormat="yyyy-MM"
                              style="width:125px"
                              placeholder="请选择">
                          </el-date-picker>
                        </div>
                      </div>
                      <div ref="productEchart" style="height: 220px; margin: 0 auto"></div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="left-center" style="margin-top: 25px">
                <div class="flex_space flex_center_align" style="margin-left: 24px">
                  <div class="lc_title">订单</div>
                </div>
                <div class="line"></div>
                <div class="common_panel">
                  <div class="panel_top">
                    <div class="panel_title">销售数量</div>
                    <div class="p_line"></div>
                  </div>
                  <div class="line_box">
                    <div class="flex_center_align">
                      <div>
                        <span>时间：</span>
                        <el-date-picker
                            v-model="orderDate"
                            type="daterange"
                            valueFormat="yyyy-MM-dd"
                            @change="orderDateChange"
                            :clearable="false"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                      </div>

                    </div>
                    <div ref="orderEchart" style="height: 220px; margin: 0 auto"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <newMode ref="newMode"></newMode>
      <h-login ref="loginMode" @ok="ok"></h-login>
    </v-scale-screen>
  </div>
</template>
<script>
import MoreLineChart from '@/components/chart/MoreLineChart.vue'
import newMode from '@/components/newMode.vue'
import hLogin from '@/components/login.vue'
import {barOptions, productOptions} from '@/utils/options'
import {
  getBannerList,
  getAppMaterProduCount,
  getAppInspectCount,
  getProcureList,
  getAppOrderCount,
  getProductSkuList,
  getProductRecordCount,
  getMaterialSkuList,
  getSupMaterCount, getSupMaterSaleCount
} from '@/api/api.js'
import * as echarts from 'echarts'
import { setTimeout } from 'timers'
export default {
  components: {
    MoreLineChart,
    newMode,
    hLogin
  },
  data() {
    return {
      materialList: [],
      productDate: '',
      productValue: '',
      productList: [],
      orderDate: '',
      inspectTotal: {
        endProductOkCount: 0,
        endProductNoCount: 0,
        halfProductOkCount: 0,
        halfProductNoCount: 0,
        materialOkCount: 0,
        materialNoCount: 0,
      },
      materialSum: 0,
      materialUseCount: 0,
      materialResidueCount: 0,
      productSum: 0,
      productUseCount: 0,
      productResidueCount: 0,
      materialRadio: 1,
      productRadio: 1,
      materialObj: {
        className: 'chart',
        name: '',
        chartTitle:'个',
        legend: [],
        xAxisData: [],
        yAxisData: [],
        randomCode: ''
      },
      imgList: [],
      materialValue: '',
      supMaterValue: '',
      materialOptions: [],
      materialDate: '',
      supMaterDate: '',
      procureList: [],
      rolltimer: '',
      show: false,
      content: {},
      nerwstimer: '',
      nowDate: '',
      nowTimer: null,
      baseForm: {}
    }
  },
  filters: {
    formartParth(data, total) {
      let num = (data / total * 100).toFixed(2)
      return num + '%'
    }
  },
  methods: {
    initChart(data) {
      // 左下角
      barOptions.xAxis.data = data.xAxisData
      barOptions.yAxis.axisLine.lineStyle.color = '#fff'
      // barOptions.yAxis.axisLabel.textStyle.fontSize = 7
      barOptions.grid.x = 40
      barOptions.grid.bottom = 40
      barOptions.yAxis.name = '个'
      barOptions.series[0] = {
        type: 'bar',
        showBackground: false,
        barMaxWidth: 16, // 每一个都要设置
        barMinWidth: 5, // 每一个都要设置
        itemStyle: {
          barBorderRadius: [6, 6, 6, 6],
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#FFFFFF'
            },
            {
              offset: 1,
              color: '#0063B8'
            }
          ]),
          emphasis: {
            label: {
              show: true,
              position: 'top',
              color: '#fff'
            }
          }
        },
        data: data.yAxisData
      }
      let chart = echarts.init(this.$refs.industry, null, {
        renderer: 'svg'
      })
      chart.clear()
      chart.setOption(barOptions)
    },
    // 原料环形图
    drowMaterialCircle(data) {
      let circleOption = {
        tooltip: {
          trigger: 'item'
        },
        color: ['#4DB9A5', '#9523CB'],
        legend: {
          show: false,
          orient: 'vertical',
          icon: 'circle',
          right: '0',
          top: '10%',
          textStyle: {
            color: '#fff'
          },
          data: ['消耗量', '剩余量']
        },
        series: [
          {
            name: '原料',
            type: 'pie',
            radius: ['69%', '84%'],
            center: ['38%', '50%'],
            avoidLabelOverlap: false,
            labelLine: {
              show: false
            },
            label: {
              show: false,
              position: 'center'
            },
            data: [
              {
                value: data.materialUseCount,
                name: '消耗量'
              },
              {
                value: data.materialResidueCount,
                name: '剩余量'
              }
            ]
          }
        ]
      }
      let cricle = echarts.init(this.$refs.materialCircle, null, {
        renderer: 'svg'
      })
      cricle.clear()
      cricle.setOption(circleOption)
    },
    // 产品环形图
    drowProductCircle(data) {
      let circleOption = {
        tooltip: {
          trigger: 'item'
        },
        color: ['#4DB9A5', '#9523CB'],
        legend: {
          show: false,
          orient: 'vertical',
          icon: 'circle',
          right: '0',
          top: '10%',
          textStyle: {
            color: '#fff'
          },
          data: ['消耗量', '剩余量']
        },
        series: [
          {
            name: '产品',
            type: 'pie',
            radius: ['69%', '84%'],
            center: ['38%', '50%'],
            avoidLabelOverlap: false,
            labelLine: {
              show: false
            },
            label: {
              show: false,
              position: 'center'
            },
            data: [
              {
                value: data.productUseCount,
                name: '消耗量'
              },
              {
                value: data.productResidueCount,
                name: '剩余量'
              }
            ]
          }
        ]
      }
      let cricle = echarts.init(this.$refs.productCircle, null, {
        renderer: 'svg'
      })
      cricle.clear()
      cricle.setOption(circleOption)
    },
    // 产品柱形图
    drowProductEchart(data){
      productOptions.series[0].data = data.residual
      productOptions.series[1].data = data.useCount
      let product = echarts.init(this.$refs.productEchart, null, {
        renderer: 'svg'
      })
      product.clear()
      product.setOption(productOptions)
    },
    // 订单图
    drowOrderEchart(data){
      //订单
      barOptions.xAxis.data = data.productNames
      barOptions.yAxis.axisLine.lineStyle.color = '#fff'
      // barOptions.yAxis.axisLabel.textStyle.fontSize = 7
      barOptions.grid.x = 40
      barOptions.grid.bottom = 40
      barOptions.yAxis.name = '个'
      barOptions.series[0] = {
        type: 'bar',
        showBackground: false,
        barMaxWidth: 16, // 每一个都要设置
        barMinWidth: 5, // 每一个都要设置
        itemStyle: {
          barBorderRadius: [6, 6, 6, 6],
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#FFFFFF'
            },
            {
              offset: 1,
              color: '#0063B8'
            }
          ]),
          emphasis: {
            label: {
              show: true,
              position: 'top',
              color: '#fff'
            }
          }
        },
        data: data.productCounts
      }
      let orderEchart = echarts.init(this.$refs.orderEchart, null, {
        renderer: 'svg'
      })
      orderEchart.clear()
      orderEchart.setOption(barOptions)
    },
    //检查登录
    checkLogin() {
      if (!sessionStorage.getItem('userInfo')) {
        this.msgError('请先登录')
        this.$refs.loginMode.show = true
        return
      }

      this.ok()
    },
    // 查询接口
    ok() {
      this.baseForm = JSON.parse(sessionStorage.getItem('userInfo'))
      this.materialRadio = '1'
      this.productRadio = '1'
      this.getBannerList()
      this.getAppMaterCount(1, 1)
      this.getAppProductCount(0, 1)
      this.getAppInspectionCount()

      this.getProcureList()
      this.getProductRecordCount()
      this.getProductSkuList()
      this.getMaterialSkuList()

      let nDate =  this.$moment().format('YYYY-MM-DD')
      let sevenAgo = this.$moment().add(-7, 'd').format("YYYY-MM-DD")
      this.orderDate = [sevenAgo, nDate]
      this.getOrderCount()

      this.getNowDate()
    },
    supMaterDateChange(val){
      this.getSupMaterCount()
    },
    supMaterChange(val){
      this.getSupMaterCount()
    },
    // 查询库存原料列表
    getMaterialSkuList(){
      getMaterialSkuList().then(res => {
        if (res.code == 200) {
          this.materialOptions = res.result.map((e) => {
            return {
              label: e.materialCode || '',
              value: e.supplierMaterialId,
            }
          })
          this.supMaterValue = this.materialOptions[0].value
          this.materialValue = this.materialOptions[0].value
          this.getSupMaterSaleCount()
          this.getSupMaterCount()
        }
      })
    },
    getSupMaterSaleCount(){
      getSupMaterSaleCount({
        materialValue: this.materialValue,
        materialDate: this.materialDate
      }).then(res => {
        if (res.code == 200) {
          this.materialObj.xAxisData = res.result.xAxisData
          this.materialObj.yAxisData = res.result.yAxisData
          this.materialObj.legend = res.result.legend
          this.materialObj.randomCode = Math.floor(100000 + Math.random() * 900000).toString()

        }
      })
    },
    materialChange(val){
      this.getSupMaterSaleCount()
    },
    getSupMaterCount(){
      getSupMaterCount({
        supMaterValue: this.supMaterValue,
        supMaterDate: this.supMaterDate
      }).then(res => {
        if (res.code == 200) {
          this.$nextTick(() => {
            this.initChart(res.result)
          })
        }
      })
    },
    // 查询库存产品列表
    getProductSkuList(){
      getProductSkuList().then(res => {
        if (res.code == 200) {
          this.productList = res.result.map((e) => {
            return {
              label: e.productNumber || '',
              value: e.productId,
            }
          })
        }
      })
    },
    // 每月产品消耗剩余统计
    getProductRecordCount(){
      getProductRecordCount({
        productValue: this.productValue,
        productDate: this.productDate
      }).then(res => {
        if (res.code == 200) {
          this.$nextTick(() => {
            let data = {
              useCount: res.result.useCount,
              residual: res.result.residual
            }
            this.drowProductEchart(data)
          })
        }
      })
    },
    // 时间范围
    orderDateChange(val){
      if(!val){
        return
      }
      this.getOrderCount()
    },
    // 订单统计
    getOrderCount(){
      getAppOrderCount({
        orderDate: this.orderDate
      }).then(res => {
        if (res.code == 200) {
          this.$nextTick(() => {
            this.drowOrderEchart(res.result)
          })
        }
      })
    },
    // 质检环形统计图
    getAppInspectionCount(){
      getAppInspectCount().then(res => {
        if (res.code == 200) {
          this.$nextTick(() => {
            this.drowInspection(res.result)
            this.inspectTotal = res.result
          })
        }
      })
    },
    productRadioChange(val){
      this.getAppProductCount(0, val)
    },
    materialRadioChange(val){
      this.getAppMaterCount(1, val)
    },
    // 产品环形统计图
    getAppProductCount(productType, timeType){
      getAppMaterProduCount({
        productType: productType,
        timeType: timeType
      }).then(res => {
        if (res.code == 200) {
          setTimeout(() => {
            this.drowProductCircle(res.result)
            this.productSum = res.result.productSum
            this.productUseCount = res.result.productUseCount
            this.productResidueCount = res.result.productResidueCount
          }, 1000)

        }
      })
    },
    // 原料环形统计图
    getAppMaterCount(productType, timeType){
      getAppMaterProduCount({
        productType: productType,
        timeType: timeType
      }).then(res => {
        if (res.code == 200) {
          setTimeout(() => {
            this.drowMaterialCircle(res.result)
            this.materialSum = res.result.materialSum
            this.materialUseCount = res.result.materialUseCount
            this.materialResidueCount = res.result.materialResidueCount
          }, 1000)

        }
      })
    },
    //轮播图
    getBannerList(){
      getBannerList().then(res => {
        if (res.code == 200) {
          if(res.result && res.result.length > 0){
            this.imgList = res.result[0].imgUrl.split(",")
          }
        }
      })
    },
    //质检成品环形图
    drowInspection(data) {
      // 成品
      let edOption = {
        tooltip: {
          trigger: 'item'
        },
        color: ['#4DB9A5', '#F9B814'],
        legend: {
          show: false
        },
        series: [
          {
            name: '成品',
            type: 'pie',
            radius: ['72%', '87%'],
            center: ['50%', '50%'],
            avoidLabelOverlap: false,
            hoverAnimation: false,
            labelLine: {
              show: false
            },
            label: {
              show: false,
              position: 'center'
            },
            data: [
              {
                value: data.endProductOkCount,
                name: '合格'
              },
              {
                value: data.endProductNoCount,
                name: '不合格'
              }
            ]
          }
        ]
      }
      let endProduct = echarts.init(this.$refs.endProduct, null, {
        renderer: 'svg'
      })
      endProduct.clear()
      endProduct.setOption(edOption)

      // 半成品
      let partyOption = {
        tooltip: {
          trigger: 'item'
        },
        color: ['#4DB9A5', '#F9B814'],
        legend: {
          show: false
        },
        series: [
          {
            name: '半成品',
            type: 'pie',
            radius: ['72%', '87%'],
            center: ['50%', '50%'],
            avoidLabelOverlap: false,
            hoverAnimation: false,
            labelLine: {
              show: false
            },
            label: {
              show: false,
              position: 'center'
            },
            data: [
              {
                value: data.halfProductOkCount,
                name: '合格'
              },
              {
                value: data.halfProductNoCount,
                name: '不合格'
              }
            ]
          }
        ]
      }
      let party = echarts.init(this.$refs.partyage, null, {
        renderer: 'svg'
      })
      party.clear()
      party.setOption(partyOption)

      // 材料质检
      let materialOption = {
        tooltip: {
          trigger: 'item'
        },
        color: ['#4DB9A5', '#F9B814'],
        legend: {
          show: false
        },
        series: [
          {
            name: '原料',
            type: 'pie',
            radius: ['72%', '87%'],
            center: ['50%', '50%'],
            avoidLabelOverlap: false,
            hoverAnimation: false,
            labelLine: {
              show: false
            },
            label: {
              show: false,
              position: 'center'
            },
            data: [
              {
                value: data.materialOkCount,
                name: '合格'
              },
              {
                value: data.materialNoCount,
                name: '不合格'
              }
            ]
          }
        ]
      }
      let material = echarts.init(this.$refs.materialInspect, null, {
        renderer: 'svg'
      })
      material.clear()
      material.setOption(materialOption)
    },
    //查询采购单
    getProcureList() {
      getProcureList().then(res => {
        if (res.code == 200) {
          this.procureList = res.result
          //滚动
          this.setautoRoll()
        }
      })
    },
    //滚动
    setautoRoll(stop) {
      if (stop && this.rolltimer) {
        window.clearInterval(this.rolltimer)
        return
      }
      const table = this.$refs.tab
      // 拿到表格中承载数据的div元素
      const divData = table.bodyWrapper
      console.log(table)

      // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每100毫秒移动1像素)
      if (stop) {
        //再通过事件监听，监听到 组件销毁 后，再执行关闭计时器。
        window.clearInterval(this.rolltimer)
      } else {
        this.rolltimer = setInterval(() => {
          // 元素自增距离顶部1像素
          divData.scrollTop += 1
          // debugger
          if (divData.clientHeight + divData.scrollTop == divData.scrollHeight) {
            divData.scrollTop = 0
          }
        }, 100) // 滚动速度
        console.log(this.rolltimer)
      }
    },
    getNowDate() {
      this.nowDate = this.$moment().format('YYYY-MM-DD HH:mm:ss')
      this.nowTimer = setInterval(() => {
        this.nowDate = this.$moment().format('YYYY-MM-DD HH:mm:ss')
      }, 1000)
    }
  },
  mounted() {
    this.checkLogin()
  },
  destroyed() {
    // if (this.rolltimer) window.clearInterval(this.rolltimer)
    this.setautoRoll(true)
    if (this.nerwstimer) window.clearInterval(this.nerwstimer)
  }
}
</script>
<style lang="scss" scope>
@font-face {
  font-family: 'JDLangZhengTi';
  src: url('../assets/fonts/JDLangZhengTi.ttf') format('truetype');
}
@font-face {
  font-family: 'ShiShangZhongHeiJianTi';
  src: url('../assets/fonts/ShiShangZhongHeiJianTi.ttf') format('truetype');
}

// 公共标题
.lc_title {
  font-family: JDLangZhengTi;
  font-size: 30px;
  font-weight: 400;
  line-height: 32px;
  color: #fff;
  margin-left: 21px;
  position: relative;
}

.lc_title::before {
  content: '';
  display: inline-block;
  width: 7px;
  height: 32px;
  background: #0099ff;
  box-shadow: 0px 0px 13px 1px #0099ff;
  position: absolute;
  top: 0;
  left: -21px;
}
.common_panel {
  width: 100%;
  height: auto;
  background: linear-gradient(90deg, #0d1836 0%, rgba(13, 24, 54, 0) 100%);
  overflow: hidden;
  clear: both;
  .panel_top {
    padding: 17px 0 0 31px;
  }
  .panel_title {
    font-family: JDLangZhengTi;
    font-size: 20px;
    font-weight: 400;
    color: #0099ff;
    margin-bottom: 11px;
  }
  .p_line {
    width: 419px;
    height: 2px;
    background: linear-gradient(90deg, #dad6da 0%, rgba(7, 128, 174, 0) 100%);
  }
}
.line {
  background: linear-gradient(90deg, #0780ae 0%, rgba(7, 128, 174, 0) 100%);
  height: 2px;
  margin-top: 21px;
}

.circle {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  border: 5px solid #ffffff;
}
.news_box {
  height: 34px;
  width: 400px;
  background: linear-gradient(90deg, rgba(196, 153, 88, 0.33) 0%, rgba(13, 24, 54, 0) 100%);
  font-family: Microsoft YaHei;
  font-size: 25px;
  font-weight: 400;
  .news_rect {
    width: 6px;
    height: 6px;
    box-shadow: 0px 0px 13px 1px #ffffff;
    background: #ffffff;
    margin: 0 18px 0 24px;
  }
  .news_name{
    width:220px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  .news_img {
    width: 28px;
    height: 28px;
    margin-left: 6px;
  }
}
.screenBox {
  width: 100%;
  height: 100%;
  // background-image: url('./assets/img/bg1.png');
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  color: #fff;
  .videobgd{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, rgba(41, 6, 22, 0.8), rgba(8, 16, 64, 0.8));
  }
  .top_title {
    font-family: JDLangZhengTi;
    font-size: 34px;
    font-weight: 400;
    text-align: center;
    color: #c4e6fe;
    text-shadow: #0099ff 0px 0px 15px;
    width: 1471.5px;
    height: 83px;
    line-height: 83px;
    margin: 0 auto;
    background-image: url('../assets/img/itop.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .allCont {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: -84px;
    position: relative;
  }
  .nav_right {
    position: absolute;
    right: 0;
    top: 36px;
    width: 563px;
    height: 34px;
    .date_box {
      font-family: JDLangZhengTi;
      color: #c4e6fe;
      text-shadow: #0099ff 0px 0px 15px;
      font-size: 25px;
      font-weight: 400;
    }
  }
  .panel_material {
    width: 349px;
    height: 178px;
    padding: 15px 0 10px 34px;
    .panel_material_box {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .material_btn_box {
        width: 60px;
        height: 30px;
        border-radius: 5px;
        background-color: #60CAEC;
        .item_btn {
          cursor: pointer;
          font-size: 15px;
        }
      }
    }

    .panel_cun_name {
      font-size: 12px;
      line-height: 15.96px;
      margin-left: 6px;
    }
    .panel_cun_num {
      font-family: ShiShangZhongHeiJianTi;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      margin-left: 6px;
    }
    .materialVue {
      width: 285px;
      height: 130px;
      position: relative;
      // overflow: hidden;
      margin-top: 30px;
      .echartsBg {
        position: absolute;
        width: 78%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .age_img {
        width: 42px;
        height: 42px;
      }
      .age_num {
        font-family: ShiShangZhongHeiJianTi;
        font-size: 19px;
        font-weight: 400;
        line-height: 38px;
        // letter-spacing: 0em;
        // text-align: center;
        text-shadow: 0px 1px 10px #0099ff;
      }
      .echartsLeg {
        position: absolute;
        right: -30px;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        .panel_cun_name {
          font-size: 14px;
          line-height: 18.48px;
        }
        .panel_cun_num {
          font-family: ShiShangZhongHeiJianTi;
          font-size: 22px;
          font-weight: 400;
          line-height: 26px;
          text-shadow: 0px 1px 10px #0099ff;
          margin-top: 4px;
        }
      }
    }
  }
  .nsLeft {
    width: 500px;
    margin-top: 115px;
  }
  .line_box {
    width: 100%;
    height: 242px;
    margin: 15px 0 22px 34px;
  }
  .nscenter {
    width: 903px;
    margin-top: 115px;
    .center_img {
      width: 903px;
      margin: 0 auto;
      height: 383px;
    }
    .center_bottom {
      display: flex;
      align-items: flex-start;
      .center_bleft {
        flex: 1.5;
        margin-right: 27px;
        margin-left: 3px;
      }
      .center_nright {
        flex: 1;
        margin-right: 24px;
      }
      .party_box {
        height: 193px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
      }
      .party_ins {
        flex: 1;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        .circle {
          margin-right: 8px;
        }
        .echartsBg {
          position: absolute;
          width: 78%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .age_img {
          width: 42px;
          height: 42px;
        }
        .age_num {
          font-family: ShiShangZhongHeiJianTi;
          font-size: 19px;
          font-weight: 400;
          line-height: 38px;
          text-shadow: 0px 1px 10px #0099ff;
        }
        .echartsLeg {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;

          .panel_cun_name {
            font-size: 14px;
            line-height: 18.48px;
          }
          .panel_cun_num {
            font-family: ShiShangZhongHeiJianTi;
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            text-shadow: 0px 1px 10px #0099ff;
            margin-top: 4px;
            .span1 {
              width: 50px;
              display: inline-block;
            }
          }
        }
      }
      .party_desc {
        width: 301px;
        height: 42px;
        display: flex;
        position: relative;
      }
    }
  }
  .nsright {
    width: 500px;
    margin-top: 115px;
    .right_item {
      width: 354px;
      font-family: Microsoft YaHei;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #fff;
      margin: 14px 0 0 37px;
      &_box {
        flex: 1;
        margin-bottom: 21px;
        .item_title {
          margin-bottom: 8px;
        }
        .muNum {
          font-family: ShiShangZhongHeiJianTi;
          font-size: 30px;
          font-weight: 400;
          line-height: 36px;
          text-shadow: 0px 1px 10px #0099ff;
          margin-right: 6px;
        }
        .mu {
          color: #0099ff;
        }
      }
    }
  }
}
.bottom_table{
  margin-left: 17px;
}
.bottom_table .el-table th.el-table__cell {
  background-color: transparent !important;
  color: #ceb77b !important;
  height: 0.15rem !important;
}
.bottom_table .el-table thead th.el-table__cell .cell{

  color: #ceb77b !important;
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
}
.bottom_table .el-table .el-table__cell {
  padding: 0 !important;
  height: 0.15rem !important;
  font-size: 0.12rem;
}
.bottom_table .el-table thead {
  color: #fff !important;
  font-size: 0.12rem !important;
}
.bottom_table > .el-table,
.el-table__expanded-cell {
  background-color: transparent !important;
}
.bottom_table .el-table tr {
  color: #fff !important;
  background: transparent !important;
  height: 18px;
}

/* 用来设置当前页面element全局table 选中某行时的背景色*/
.bottom_table .el-table__body tr.current-row > td {
  background-color: inherit !important;
  /* color: #f19944; */ /* 设置文字颜色，可以选择不设置 */
}
/* 用来设置当前页面element全局table 鼠标移入某行时的背景色*/
.bottom_table .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: inherit !important;
  /* color: #f19944; */ /* 设置文字颜色，可以选择不设置 */
}
.bottom_table .el-table__body-wrapper {
  overflow: hidden !important;
}
.bottom_table .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border: none !important;
}
.bottom_table .el-table__body,
.el-table__footer,
.el-table__header {
  width: 100% !important;
}
.bottom_table .el-table .cell {
  font-family: MicrosoftYaHei;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.bottom_table .el-table::before {
  height: 0 !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.panel1::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.panel1 {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.panel1 {
  height: 500px;
  overflow-y: scroll;
}
</style>
