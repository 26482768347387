<template>
  <div class="main">
    <v-scale-screen :fullScreen="false" width="1920" height="1080" v-if="!['home'].includes(name)">
      <div id="app">
        <!-- <p-head :title="name" type="home" v-if="!['3dmap','dataCenter','integrated', 'video'].includes(name)"></p-head> -->
        <router-view />
      </div>
    </v-scale-screen>
    <div id="app" v-else>
      <router-view />
    </div>
  </div>
</template>

<script>
import pHead from '@/components/pageHead.vue'
import Router from 'vue-router'
export default {
  name: 'App',
  components: {
    pHead
  },
  data() {
    return {
      title: ''
    }
  },
  computed: {
    name() {
      return this.$route.name
    }
  },
  methods: {},
  mounted() {}
}
</script>

<style lang="scss">
@import '@/common/css/chart';
@import '@/common/css/common';
* {
  margin: 0;
  padding: 0;
}
body {
  overflow: hidden;
  font-family: 'Misrosoft YaHei';
  text-rendering: optimizeLegibility;
  /* 添加平滑处理代码 */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: 'Misrosoft YaHei';
  text-rendering: optimizeLegibility;
  /* 添加平滑处理代码 */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.main,
#app,
.container {
  /* color: #fff; */
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  font-family: 'Misrosoft YaHei';
  text-rendering: optimizeLegibility;
  /* 添加平滑处理代码 */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
